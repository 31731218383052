<template>
  <FocusTrap>
    <div id="mycard">

      <div class="page-header page-header-light">

        <div class="breadcrumb-line breadcrumb-line-light header-elements-lg-inline">
          <div class="d-flex">
            <div class="breadcrumb">
              <a href="#" class="breadcrumb-item" tabindex="-1"><i class="icon-home2 mr-2"></i> Home</a>
              <a href="#" class="breadcrumb-item" tabindex="-1">Search</a>
              <span class="breadcrumb-item active">Book</span>
            </div>

            <a href="#" class="header-elements-toggle text-body d-lg-none" tabindex="-1"><i class="icon-more"></i></a>
          </div>

          <div class="header-elements d-none">
            <div class="breadcrumb justify-content-center">
              <a tabindex="-1" href="#" class="breadcrumb-elements-item dropdown-toggle" data-toggle="dropdown">
                <i class="icon-menu7"></i>
              </a>

              <div class="dropdown-menu dropdown-menu-right">
                <a tabindex="-1" href="#" class="dropdown-item"><i class="icon-user-lock"></i> Account security</a>
                <a href="#" class="dropdown-item"><i class="icon-statistics"></i> Analytics</a>
                <a href="#" class="dropdown-item"><i class="icon-accessibility"></i> Accessibility</a>
                <div class="dropdown-divider"></div>
                <a href="#" class="dropdown-item"><i class="icon-gear"></i> All settings</a>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="card">

        <div class="card-header pt-1">
          <div class="row align-items-end" style="background-color: whitesmoke;"  >

            <div class="col-md-1 form-group">
              <label class="control-label">Type</label>
              <select class="form-control" v-model="search_type">
                <option value="Book">Book</option>
                <option value="BackVolume">Back Volume</option>
                <option value="Periodical">Periodical</option>
                <option value="Digital">Digital</option>
              </select>
            </div>

            <div class="col-md-1 form-group ">
              <label for="txtAccessionNo">Accession No</label>
              <input id="txtAccessionNo" type="number" class="form-control" placeholder="Accession No" name="txtAccessionNo" min="1" autocomplete="off" v-model="search_accession_no" autofocus @keydown.enter="loadSearchBooks">
            </div>

            <div class="col-md-3 form-group ">
              <label for="txtTitle">Title</label>
              <input id="txtTitle" type="text" class="form-control" placeholder="Book Title" name="txtTitle" autocomplete="off" v-model="search_title" @keydown.enter="loadSearchBooks">
            </div>

            <div class="col-md-2 form-group ">
              <label >Author</label>
              <div class="input-group">
                <select class="form-control" v-model="search_author_id" >
                  <option v-for="pub in authors" v-bind:value="pub.id">
                    {{ pub.name }}
                  </option>
                </select>
                <span>  <button class="btn btn-outline-success pr-1 pl-1" type="button" v-if="search_author_id > 1" @click="loadSearchBooks"> <i class="icon-search4" ></i>  </button></span>
              </div>

            </div>

            <div class="col-md-2 form-group ">
              <label >Publisher</label>
              <div class="input-group">
                <select class="form-control" v-model="search_publisher_id" >
                  <option v-for="pub in publishers" v-bind:value="pub.id">
                    {{ pub.name }}
                  </option>
                </select>
                <span>  <button class="btn btn-outline-success pr-1 pl-1" type="button"  v-if="search_publisher_id > 1" @click="loadSearchBooks"> <i class="icon-search4" ></i>  </button></span>
              </div>
            </div>

            <div class="col-md-2 form-group ">
              <label>Department</label>
              <div class="input-group">
              <select class="form-control" v-model="search_department_id" >
                <option v-for="pub in departments" v-bind:value="pub.id">
                  {{ pub.name }}
                </option>
              </select>
                <span>  <button class="btn btn-outline-success pr-1 pl-1" type="button"  v-if="search_department_id > 1" @click="loadSearchBooks"> <i class="icon-search4" ></i>  </button></span>
              </div>
            </div>

            <div class="col-md-1 form-group text-right" >
              <!--            <label>Search</label>-->
              <button class="btn btn-secondary icon-search4" @click="loadSearchBooks()"></button>
            </div>


          </div>
        </div>

        <div class="card-body">
          <div class="card card-table table-responsive shadow-none mb-0 ">
            <table class="table table-bordered table-hover ">
              <thead class="bg-teal-800">
                <tr>
<!--                <th style="width: 50px;">Status</th>-->
                <th style="width: 75px;">Accession</th>
                <th>Title</th>
                <th>Category</th>
                <th>Author</th>
                <th>Publisher</th>
                <th style="width: 50px;">Location</th>
              </tr>
              </thead>
              <tbody>
                <tr v-for="(book, key) in books" :class="applyRowClass(book)" >
<!--                  <td style="width: 50px;">{{book.status}}</td>-->
                  <td style="width: 75px;">{{book.code}}</td>
                  <td>{{book.name}}</td>
                  <td>{{book.category.name}}</td>
                  <td>{{book.author_name}}</td>
                  <td>{{book.publisher.name}}</td>
                  <td style="width: 50px;">{{book.rack}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

    </div>
  </FocusTrap>
</template>

<script>

import { store } from '@/store/store.js';
import { userService } from '@/store/auth-header.js';

export default {
  name: 'BookSearchForm',
  store,
  components: {},
  data () {
    return {
      mytable: $('#book_table'),
      book: JSON.parse('{"id":0,"status":"Active","name":"","title":"","language":"en","edition":"","edition_year":0,"publisher_id":0,"publisher_year":0,"group_id":0,"category_id":0,"rack":"","qty":0,"price":0,"pages":0,"notes":"","image":"","publisher":{"id":0,"name":""},"category":{"id":0,"name":""},"group":{"id":0,"name":""},"authors":[]}'),
      publishers:[],
      departments:[],
      authors:[],
      categories:[],
      search_type : 'Book',
      search_accession_no : '',
      search_title : '',
      search_author_id : 0,
      search_publisher_id : 0,
      search_department_id : 0,
      books : [],
    }
  },
  created () {
    const self = this;

  },
  mounted () {
    const self = this;
    try {
      this.$data.mytable = $('#book_table');
      this.$data.mytable.bootstrapTable();
      // this.loadData();

      this.loadDepartments();
      this.loadAuthors();
      this.loadPublishers();
      this.loadCategories();

    }catch (e) {
      alert(e);
    }
  },
  methods: {
    applyRowClass(book){
      switch (book.status) {
        case 'Available':
          return "";
        case 'OnCirculation':
          return "bg-danger-800";
        case 'Damaged':
          return "bg-brown";
        default:
          return '';
      }
    },
    loadPublishers(){
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: userService.authHeader()
      }

      $('#mycard').block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      self.$data.publishers = [];
      fetch(`${process.env.VUE_APP_ROOT_API}api/lms/publishers`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          if (!_.isNull(resp.data)) {
            self.$data.publishers = resp.data;
          }
        } else {
          swal({ title: 'Oops!', text: resp.msg, type: 'error' });
        }
      }).catch(function (err) {
        swal({ title: 'Oh noes!', text: err.toString(), type: 'error' });
      }).finally(()=>{
        $('#mycard').unblock();
      });
    },
    loadAuthors(){
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: userService.authHeader()
      }

      $('#mycard').block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      self.$data.authors = [];
      fetch(`${process.env.VUE_APP_ROOT_API}api/lms/authors`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          if (!_.isNull(resp.data)) {
            self.$data.authors = resp.data;
          }
        } else {
          swal({ title: 'Oops!', text: resp.msg, type: 'error' });
        }
      }).catch(function (err) {
        swal({ title: 'Oh noes!', text: err.toString(), type: 'error' });
      }).finally(()=>{
        $('#mycard').unblock();
      });
    },
    loadDepartments(){
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: userService.authHeader()
      }

      $('#mycard').block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      self.$data.departments = [];
      fetch(`${process.env.VUE_APP_ROOT_API}api/lms/departments`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          if (!_.isNull(resp.data)) {
            self.$data.departments = resp.data;
          }
        } else {
          swal({ title: 'Oops!', text: resp.msg, type: 'error' });
        }
      }).catch(function (err) {
        swal({ title: 'Oh noes!', text: err.toString(), type: 'error' });
      }).finally(()=>{
        $('#mycard').unblock();
      });
    },
    loadCategories(){
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: userService.authHeader()
      }

      $('#mycard').block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      self.$data.categories = [];
      fetch(`${process.env.VUE_APP_ROOT_API}api/lms/categories`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          if (!_.isNull(resp.data)) {
            self.$data.categories = resp.data;
          }
        } else {
          swal({ title: 'Oops!', text: resp.msg, type: 'error' });
        }
      }).catch(function (err) {
        swal({ title: 'Oh noes!', text: err.toString(), type: 'error' });
      }).finally(()=>{
        $('#mycard').unblock();
      });
    },
    loadSearchBooks(){
      const self = this;

      try {

        let search = {
          "type" : self.$data.search_type,
          "accession_no" :  self.$data.search_accession_no,
          "title" : self.$data.search_title,
          "author_id" : self.$data.search_author_id,
          "publisher_id" : self.$data.search_publisher_id ,
          "department_id" : self.$data.search_department_id
        };

        const requestOptions = {
          method: 'POST',
          mode: 'cors',
          headers: userService.authHeader(),
          body : JSON.stringify(search),
        }


        $('body').block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });

        self.$data.books = [];
        fetch(`${process.env.VUE_APP_ROOT_API}api/lms/search_book`, requestOptions).then(userService.handleResponse).then(function (resp) {
          $('body').unblock();
          document.getElementById("mycard").style.cursor = "default";
          if (resp.ok) {
            if (!_.isNull(resp.data)) {
              self.$data.books = resp.data;
              showInfo( self.$data.books.length + ' Books found');
            }
          } else {
            swal({ title: 'Oops!', text: resp.msg, type: 'error' });
          }
        }).catch(function (err) {
          $('body').unblock();
          document.getElementById("mycard").style.cursor = "default";
          swal({ title: 'Oh noes!', text: err.toString(), type: 'error' });
        });


      }catch (e) {
        alert(e);
      }

    },
  }
}
</script>

<style>
input:focus {
  background: #feff00;
}

textarea:focus {
  background: #feff00;
}

select:focus {
  background: #feff00;
}

button:focus {
  background: #feff00 !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

select {
  /* for Firefox */
  -moz-appearance: none;
  /* for Safari, Chrome, Opera */
  -webkit-appearance: none;
}

</style>
